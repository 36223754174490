
/* body {
  font-family: 'Montserrat', sans-serif;
  color: #1a1b1f;
} */


h1, h2, h3, h4, h5, h6 {
  font-family: 'Merriweather', serif;
  color: #3057f3;
}

.MuiAppBar-colorPrimary {
  background-color: #3057f3;
}

.MuiButton-containedPrimary {
  background-color: #3057f3;
}

.MuiAppBar-root {
  transition: background-color 0.3s, color 0.3s;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
}

.card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.hero-section {
/* import hero image from assets/Hero.jpeg */
  background-image: url('https://images.unsplash.com/photo-1557683316-973673baf926');

  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 50px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.success-paper {
  padding: 2rem;
  text-align: center;
  max-width: 600px;
  width: 100%;
  margin: auto;
}



